import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { QTableProps } from "quasar";
import { computed, toRefs } from "vue";

import { Target, TargetInApp, TargetType } from "@/api/target/typedefs";
import ListContent from "@/components/shared/list/ListContent.vue";
import YesNoBadge from "@/components/shared/YesNoBadge.vue";
import { DEFAULT_ROWS_PER_PAGE, Pagination } from "@/lib/pagination";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetsList',
  props: {
    targets: {},
    targetType: {},
    isLoading: { type: Boolean },
    isError: { type: Boolean },
    pagination: {}
  },
  emits: ["rowClick", "paginationChange"],
  setup(__props: any, { emit: __emit }) {

const targetColumns: QTableProps["columns"] = [
  {
    name: "id",
    label: "ID",
    field: (row: Target) => row.id,
    sortable: true,
    align: "left",
  },
  {
    name: "name",
    label: "Name",
    field: (row: Target) => row.name,
    sortable: true,
    align: "left",
    style: "min-width: 150px",
  },
  {
    name: "target_timezone",
    label: "Timezone",
    field: (row: Target) => row.targetTimezone,
    sortable: true,
    align: "left",
  },
  {
    name: "created_by",
    label: "Created by",
    field: (row: Target) => row.createdBy,
    sortable: true,
    align: "left",
  },
  {
    name: "modified_by",
    label: "Modified by",
    field: (row: Target) => row.modifiedBy,
    sortable: true,
    align: "left",
  },
  {
    name: "created_at",
    label: "Created at",
    field: (row: Target) => row.createdAt,
    sortable: true,
    align: "left",
  },
  {
    name: "modified_at",
    label: "Modified at",
    field: (row: Target) => row.modifiedAt,
    sortable: true,
    align: "left",
  },
  {
    name: "affected_users",
    label: "Affected users",
    field: (row: Target) => row.affectedUsers,
    sortable: true,
    align: "left",
  },
  {
    name: "inactive",
    label: "Inactive",
    field: (row: Target) => row.inactive,
    sortable: true,
    align: "left",
  },
];
const inAppTargetColumns: QTableProps["columns"] = Array.from(targetColumns);
// TODO(pszyma): Fix vue-jest transformator and use spread operator.
inAppTargetColumns.push({
  name: "runtime",
  label: "Runtime",
  field: (row: TargetInApp) => row.isRuntime,
  sortable: true,
  align: "left",
});

const props = __props;
const emit = __emit;

const { targets, isLoading, isError } = toRefs(props);

const columns = computed(() => {
  if (props.targetType === "inapps") {
    return inAppTargetColumns;
  }
  return targetColumns;
});

function handlePaginationChange(payload: { pagination: Pagination }): void {
  emit("paginationChange", payload.pagination);
}

const handleRowClick = (_: Event, row: Target) => {
  emit("rowClick", row.id);
};

return (_ctx: any,_cache: any) => {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(ListContent, {
    "error-message": "Failed to fetch list of targets",
    "is-error": _unref(isError)
  }, {
    header: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "text-h6 q-py-sm" }, "Target list", -1)
    ])),
    table: _withCtx(() => [
      _createVNode(_component_q_table, {
        columns: columns.value,
        loading: _unref(isLoading),
        rows: _unref(targets),
        pagination: _ctx.pagination,
        "rows-per-page-options": _unref(DEFAULT_ROWS_PER_PAGE),
        "no-data-label": "No data available",
        flat: "",
        "data-test": "targets.list.table",
        onRowClick: handleRowClick,
        "onUpdate:pagination": ($event: any) => (void 0),
        onRequest: handlePaginationChange
      }, {
        "body-cell-inactive": _withCtx((tdProps) => [
          _createVNode(_component_q_td, { props: tdProps }, {
            default: _withCtx(() => [
              _createVNode(YesNoBadge, {
                value: tdProps.value
              }, null, 8, ["value"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-runtime": _withCtx((tdProps) => [
          _createVNode(_component_q_td, { props: tdProps }, {
            default: _withCtx(() => [
              _createVNode(YesNoBadge, {
                value: tdProps.value
              }, null, 8, ["value"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["columns", "loading", "rows", "pagination", "rows-per-page-options"])
    ]),
    _: 1
  }, 8, ["is-error"]))
}
}

})